import styled from "styled-components";
import { ButtonProps } from '@pancakeswap/uikit'

const GradientButton = styled.button<ButtonProps>`
  text-wrap: nowrap;
  background: linear-gradient(92.73deg, #FF00FF 0.61%, #FE511B 99.34%);
  width: ${({ width }) => width ?? 'fit-content'};
  height: ${({ height }) => height ?? 'auto'};
  color: white;
  padding: 6px 16px;
  font-size: 16px;
  border-radius: 100px;
  border: 0px;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

export default GradientButton;