/* eslint-disable import/order */
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Menu as UikitMenu, NextLinkFromReactRouter, footerLinks, Box, SunIcon, Flex, ChevronDownIcon, MoonIcon } from '@pancakeswap/uikit'
import { useTranslation, languageList } from '@pancakeswap/localization'
import { useTheme } from 'next-themes';
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import { usePhishingBannerManager } from 'state/user/hooks'
import UserMenu from './UserMenu'
import { useMenuItems } from './hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import styled from 'styled-components'

const Container = styled.div`
  font-size: 14px;
  background: ${({ theme }) => theme.colors.input};
  color: ${({ theme }) => theme.colors.inputText};
  padding: 6px 8px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 6px;
  position: relative;
  width: 90px;
  margin-right: 10px;
`
const SwitcherContainer = styled.div`
  font-size: 14px;
  background: ${({ theme }) => theme.isDark ? "#3a3d3f" : "#ececec"};
  color: ${({ theme }) => theme.colors.inputText};
  padding: 6px 8px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 6px;
  position: relative;
  width: 70px;
  margin-right: 10px;
`
const StyledFlex = styled(Flex)`
  &:hover {
    opacity: 0.85;
  }
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const Menu = (props) => {
  const { theme, setTheme }: any = useTheme()
  const { resolvedTheme } = useTheme()
  const [showTheme, setShowTheme] = useState(false)
  const [pricesData, setPricesData] = useState<any>({})
  const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true })
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useRouter()
  const [showPhishingWarningBanner] = usePhishingBannerManager()

  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const toggleTheme = () => {
    setTheme(resolvedTheme === 'light' ? 'dark' : 'light');
  };
  const isDark = resolvedTheme === 'dark'
  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])

  const themeRef = useRef(null);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowTheme(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(themeRef);

  const getPrices = async () => {
    const prices = await (await fetch('https://v.gopulse.com/api/prices')).json()
    const response = await (await fetch('https://v.gopulse.com/api')).json()
    const changes = {
      hexChange: response?.HEX?.timed?.priceChange?.h24,
      incChange: response?.INC?.timed?.priceChange?.h24,
      plsChange: response?.PLS?.timed?.priceChange?.h24,
      plsxChange: response?.PLSX?.timed?.priceChange?.h24,
    }
    const data = {
      prices, changes
    }
    if (data) setPricesData(data)
  }

  useEffect(() => {
    getPrices()
  }, [])

  const ThemeSelector = () => {
    return (
      <Container>
        <Flex justifyContent="space-between" alignItems="center" onClick={() => { setShowTheme(!showTheme) }}>
          {resolvedTheme === 'light'
            ?
            <><SunIcon marginRight="5px" color='secondary' width={16} /> Light</>
            :
            <><MoonIcon marginRight="5px" color='secondary' width={16} /> Dark</>
          }
          <ChevronDownIcon color='secondary' />
        </Flex>
        {/* {showTheme && */}
        <Box opacity={showTheme ? '1' : '0'} className="animation" position="absolute" width="100%" top="35px" left="0px" onClick={() => { toggleTheme(); setShowTheme(false) }} ref={themeRef}>
          <StyledFlex alignItems="center" backgroundColor="input" padding="6px" borderRadius="4px">
            {resolvedTheme !== 'light'
              ?
              <><SunIcon marginLeft="5px" marginRight="5px" color='secondary' width={16} />Light</>
              :
              <><MoonIcon marginLeft="5px" marginRight="5px" color='secondary' width={16} />Dark</>}
          </StyledFlex>
        </Box>
        {/* } */}
      </Container>
    )
  }
  const ThemeSwitcher = () => {
    return (
      <SwitcherContainer>
        <Flex justifyContent="space-between" alignItems="center" onClick={() => { toggleTheme() }}>
          {resolvedTheme === 'light'
            ?
            <><SunIcon marginRight="5px" color='secondary' width={16} /> Light</>
            :
            <><MoonIcon marginRight="5px" color='secondary' width={16} /> Dark</>
          }
        </Flex>
      </SwitcherContainer>
    )
  }
  const MessageNotifier = () => (
    <Box marginRight="4px" width={24} className={isDark ? 'dark-spread' : 'light-spread'}
      onClick={() => {
        window.open(
          'https://chat.hex.toys/',
          '_blank',
          'noreferrer noopener',
        )
      }
      }>
      <img src={resolvedTheme === 'light' ? '/icons/message_icon.svg' : '/icons/dark_message_icon.svg'} alt='hex' style={{ width: 26 }} />
    </Box >
  )
  const Bag = () => (
    <Box marginRight="12px" marginLeft="4px" className={isDark ? 'dark-spread' : 'light-spread'}>
      <img src={resolvedTheme === 'light' ? '/icons/icon_curt_black.svg' : '/icons/dark_icon_curt.svg'} alt='hex' width="26px" />
    </Box>
  )

  return (
    <>
      <UikitMenu
        linkComponent={(linkProps) => {
          return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
        }}
        rightSide={
          <>
            <UserMenu />
          </>
        }
        ThemeSelector={ThemeSelector}
        ThemeSwitcher={ThemeSwitcher}
        MessageNotifier={MessageNotifier}
        isDark={theme === 'dark'}
        toggleTheme={toggleTheme}
        currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        cakePriceUsd={cakePriceUsd}
        links={menuItems}
        subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        pricesData={pricesData}
        buyCakeLabel={t('Buy CAKE')}
        buyCakeLink="https://app.pulsex.com/swap?outputCurrency=0x95B303987A60C71504D99Aa1b13B4DA07b0790ab"
        {...props}
      />
    </>
  )
}

export default Menu
