import { ChainId, WPLS, WETH9, ERC20Token } from '@pancakeswap/sdk'
import { USDC, USDT, WBTC_PULSE } from '@pancakeswap/tokens'

export const pulseTokens = {
  wpls: WPLS[ChainId.PULSE],
  weth: WETH9[ChainId.PULSE],
  usdt: USDT[ChainId.PULSE],
  usdc: USDC[ChainId.PULSE],
  wbtc: WBTC_PULSE,
  pulsex: new ERC20Token(
    ChainId.PULSE,
    '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab',
    18,
    'PLSX',
    'PulseX',
    'https://pulsex.com/',
  ),
  dai: new ERC20Token(
    ChainId.PULSE,
    '0xefD766cCb38EaF1dfd701853BFCe31359239F305',
    18,
    'DAI',
    'Dai Stablecoin from Ethereum',
    'https://makerdao.com/',
  ),
  SAYED: new ERC20Token(
    ChainId.PULSE,
    '0xefD766cCb38EaF1dfd701853BFCe31359239F305',
    18,
    'SAYED',
    'SAYED Stablecoin from Ethereum',
    'https://makerdao.com/',
  ),
}