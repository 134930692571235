import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("Community"),
    items: [
      {
        label: t("Discord"),
        href: "https://discord.gg/GewKYcJJ",
        // isHighlighted: true,
      },
      {
        label: t("Twitter"),
        href: "https://twitter.com/HEXTOYSOFFICIAL",
      },
      {
        label: t("Instagram"),
        href: "https://www.instagram.com/hextoysofficial/",
      },
      {
        label: t("Telegram"),
        href: "https://t.me/hextoys",
      }
    ],
  },
  {
    label: t("Info & Support"),
    items: [
      {
        label: t("Blog"),
        href: "https://blog.hex.toys/",
      }, 
      {
        label: t("Help"),
        href: "https://support.hex.toys/",
      },
      {
        label: t("Discussion"),
        href: "https://support.hex.toys/",
      }
    ],
  },
];
