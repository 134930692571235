import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s',
  defaultTitle: 'DEX TOYS | PulseX Exchange',
  description:
    'Explore comprehensive token statistics, DEX volume, NFT prices, NFT collections, and Ethereum claim data on PulseChain. Get real-time insights and track the latest trends in the Pulse ecosystem.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@HEXTOYSOFFICIAL',
    site: '@HEXTOYSOFFICIAL'
  },
  openGraph: {
    title: 'PulseChain Statistics | Token Stats, DEX Volume, NFT Prices',
    description:
      'Explore comprehensive token statistics, DEX volume, NFT prices, NFT collections, and Ethereum claim data on PulseChain. Get real-time insights and track the latest trends in the Pulse ecosystem.',
    images: [{ url: 'https://dex.hex.toys/hero.jpg' }],
  },
}
