import styled from "styled-components";
import { PolymorphicComponent } from "../../util/polymorphic";
import Button from "../Button/Button";
import { BaseButtonProps, variants } from "../Button/types";
import { ButtonMenuItemProps } from "./types";
import GradientButton from "../Button/GradientButton";

interface InactiveButtonProps extends BaseButtonProps {
  forwardedAs: BaseButtonProps["as"];
}

const InactiveButton: PolymorphicComponent<InactiveButtonProps, "button"> = styled(Button) <InactiveButtonProps>`
  background-color: transparent;
  font-weight: 400;
  text-wrap: nowrap;
  color: ${({ theme }) => theme.colors.inputText};
  &:hover:not(:disabled):not(:active) {
    background-color: transparent;
  }
`;
const ActiveButton: PolymorphicComponent<InactiveButtonProps, "button"> = styled(Button) <InactiveButtonProps>`
  background-color: transparent;
  font-weight: 600;
  text-wrap: nowrap;
  color: ${({ theme }) => theme.colors.defaultText};
  &:hover:not(:disabled):not(:active) {
    background-color: transparent;
  }
`;

const ButtonMenuItem: PolymorphicComponent<ButtonMenuItemProps, "button"> = ({
  isActive = false,
  variant = variants.PRIMARY,
  isGradient = true,
  as,
  ...props
}: ButtonMenuItemProps) => {
  if (!isActive) {
    return <InactiveButton forwardedAs={as} variant={variant} {...props} />;
  }

  return isGradient ? <GradientButton as={as} variant={variant} {...props} /> : <ActiveButton forwardedAs={as} variant={variant} {...props} />;
};

export default ButtonMenuItem;
