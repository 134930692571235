import { ChainId } from '@pancakeswap/sdk'
import { useMemo } from 'react'
import { useChainCurrentBlock } from 'state/block/hooks'

export const useMenuItemsStatus = (): Record<string, string> => {
  const currentBlock = useChainCurrentBlock(ChainId.BSC)
  return {
    '/competition': '',
    '/ifo': ''
  }
}
