import poolsConfig from 'config/constants/pools'
import { PoolCategory } from 'config/constants/types'
import { CAKE } from '@pancakeswap/tokens'

// Addresses
import {
  getAddress,
  getMasterChefAddress,
  getMasterChefV1Address,
  getMulticallAddress,
} from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import cakeAbi from 'config/abi/cake.json'
import masterChef from 'config/abi/masterchef.json'
import masterChefV1 from 'config/abi/masterchefV1.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import erc721CollectionAbi from 'config/abi/erc721collection.json'

import { ChainId } from '@pancakeswap/sdk'
import { viemClients } from 'utils/viem'
import { Abi, PublicClient, WalletClient, getContract as viemGetContract } from 'viem'
import { Address } from 'wagmi'

export const getContract = <TAbi extends Abi | unknown[], TWalletClient extends WalletClient>({
  abi,
  address,
  chainId = ChainId.PULSE,
  publicClient,
  signer,
}: {
  abi: TAbi
  address: string
  chainId?: ChainId
  signer?: TWalletClient
  publicClient?: PublicClient
}) => {
  const c = viemGetContract({
    abi,
    address: (address as Address),
    // @ts-ignore
    publicClient: publicClient ?? viemClients[chainId],
    // @ts-ignore
    walletClient: signer,
  })
  return {
    ...c,
    account: signer?.account,
    chain: signer?.chain,
  }
}

export const getBep20Contract = (address: string, signer?: WalletClient) => {
  return getContract({ abi: bep20Abi, address, signer })
}
export const getErc721Contract = (address: string, signer?: WalletClient) => {
  return getContract({ abi: erc721Abi, address, signer })
}
export const getLpContract = (address: string, chainId?: number, signer?: WalletClient) => {
  return getContract({ abi: lpTokenAbi, address, signer, chainId })
}

export const getSouschefContract = (id: number, signer?: WalletClient) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract({ abi, address: getAddress(config.contractAddress), signer })
}
export const getSouschefV2Contract = (id: number, signer?: WalletClient) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract({ abi: sousChefV2, address: getAddress(config.contractAddress), signer })
}

export const getCakeContract = (chainId?: number) => {
  return getContract({
    abi: cakeAbi,
    address: chainId ? CAKE[chainId].address : CAKE[ChainId.PULSE].address,
    chainId,
  })
}

export const getMasterchefContract = (signer?: WalletClient, chainId?: number) => {
  return getContract({ abi: masterChef, address: getMasterChefAddress(chainId), signer })
}
export const getMasterchefV1Contract = (signer?: WalletClient) => {
  return getContract({ abi: masterChefV1, address: getMasterChefV1Address(), signer })
}

export const getMulticallContract = (chainId: ChainId) => {
  return getContract({ abi: MultiCallAbi, address: getMulticallAddress(chainId), chainId })
}

export const getErc721CollectionContract = (signer?: WalletClient, address?: string) => {
  return getContract({ abi: erc721CollectionAbi, address, signer })
}
