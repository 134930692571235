/* eslint-disable react/jsx-boolean-value */
import { useIsMounted } from "@pancakeswap/hooks";
import { AtomBox } from "@pancakeswap/ui/components/AtomBox";
import throttle from "lodash/throttle";
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components";
import BottomNav from "../../components/BottomNav";
import { Box } from "../../components/Box";
import Flex from "../../components/Box/Flex";
import CakePrice from "../../components/CakePrice/CakePrice";
import Footer from "../../components/Footer";
import MenuItems from "../../components/MenuItems/MenuItems";
import { SubMenuItems } from "../../components/SubMenuItems";
import { useMatchBreakpoints } from "../../contexts";
import Logo from "./components/Logo";
import { MENU_HEIGHT, MOBILE_MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from "./config";
import { MenuContext } from "./context";
import { NavProps } from "./types";
import { AddCircleIcon, CloseIcon, HamburgerIcon, Input, Text } from "../../components";


const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  background: ${({ theme }) => theme.colors.backgroundAlt};
`;

const StyledNav = styled.nav<{ isDark: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: ${MENU_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme, isDark }) => isDark ? 'transparent' : theme.colors.cardBorder};
  transform: translate3d(0, 0, 0);
  padding: 8px 60px;
  @media screen and (max-width: 1190px) {
    padding: 8px 20px;
  }
  @media screen and (max-width: 576px) {
    padding: 8px 10px;
  }
`;

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: fixed;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${154}px`)};
  @media screen and (max-width: 1102px) {
    top: ${({ showMenu, height }) => (showMenu ? 0 : `-${188}px`)};
  }
  left: 0;
  transition: top 0.2s;
  // height: ${({ height }) => `${height}px`};
  width: 100%;
  z-index: 20;
  background: ${({ theme }) => theme.colors.default};
`;

const TopBannerContainer = styled.div<{ height: number, isDark: boolean }>`
  height: fit-content;
  min-height: 61px;
  width: 100%;
  border-bottom: 1px solid ${({ theme, isDark }) => isDark ? 'transparent' : theme.colors.cardBorder};
  background: ${({ theme }) => theme.colors.backgroundTop};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10%;
  @media screen and (max-width: 900px) {
    padding: 0px 5%;
  }
  @media screen and (max-width: 852px) {
    padding: 0px 20px;
  }
  @media screen and (max-width: 577px) {
    padding: 0px 10px;
  }
  max-width: 100%;
`;

const SubContainer = styled.div`
  display: flex;
  width: fit-content;
  min-width: 60%;
  gap: 20px;
  margin-top: 6px;
  margin-bottom: 6px;
  justify-content: space-between;
  ::-webkit-scrollbar {
    display: none;
  }
  overflow-x: auto;
  white-space: nowrap;
  @media screen and (max-width: 853px) {
    width: 100%;
    gap: 45px;
  }
`;

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  max-width: 100vw;
  overflow-x: hidden;
`;

const Inner = styled.div`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`;

const BuyPlusGradientButton = styled.button`
  padding: 0;
  background: linear-gradient(90deg, #69EACB 0%, #EACCF8 48%, #6654F1 100%);
  width: 100%;
  justify-content: center;
  color: black;
  padding: 6px 16px;
  align-items: center;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  border-radius: 100px;
  border: 0px;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

const SubItemContainer = styled(Flex)`
  &:hover {
    opacity: 0.8;
  }
  padding: 10px;
  justify-content: center;
  width: 100%;
`
const InputEnd = styled.div`
  position: absolute;
  border-radius: 6px;
  right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.secondaryDark};
  width: 26px;
  height: 26px
`
const SearchInput = styled.input`
  background-color: ${({ theme }) => theme.colors.input};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.defaultText};
  display: block;
  font-size: 16px;
  outline: 0;
  padding: 0 38px 0px 16px;
  @media screen and (max-width: 577px) {
    padding: 0 8px 0px 8px;
    border-radius: 8px;
    font-size: 14px;
  }
  height: 40px;
  width: 100%;
  border: solid 1px transparent;
  margin-left: 8px;
  &::placeholder {
    font-weight: 500;
    color: #777;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.textDisabled};
    cursor: not-allowed;
  }
  &:focus:not(:disabled) {
    border: solid 1px ${({ theme }) => theme.colors.secondaryDark};
  }
`;

const Menu: React.FC<React.PropsWithChildren<NavProps>> = ({
  linkComponent = "a",
  rightSide,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  cakePriceUsd,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyCakeLabel,
  buyCakeLink,
  children,
  pricesData,
  MessageNotifier,
  ThemeSelector,
  ThemeSwitcher,
}) => {
  const { isMobile, isMd } = useMatchBreakpoints();
  const isMounted = useIsMounted();
  const { pathname } = useRouter()
  const [showMenu, setShowMenu] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const refPrevOffset = useRef(typeof window === "undefined" ? 0 : window.pageYOffset);

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT;

  const totalTopMenuHeight = isMounted ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT;

  const moveLeftRef = useRef<boolean>(true)
  const increaseRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (increaseRef.current) {
        if (increaseRef.current.scrollLeft === increaseRef.current.scrollWidth - increaseRef.current.clientWidth) {
          moveLeftRef.current = false
        } else if (increaseRef.current.scrollLeft === 0) {
          moveLeftRef.current = true
        }
        increaseRef.current.scrollTo(
          moveLeftRef.current ? increaseRef.current.scrollLeft + 1 : increaseRef.current.scrollLeft - 1,
          0,
        )
      }
    }, 30)

    return () => {
      clearInterval(scrollInterval)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    };

    // Get Prices Data


    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [totalTopMenuHeight]);
  const isBridge = pathname === '/bridge'
  // Find the home link if provided
  const homeLink = links.find((link) => link.label === "Home");
  const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly);
  const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly);
  const providerValue = useMemo(() => ({ linkComponent }), [linkComponent]);

  const [showNavMenu, setShowNavMenu] = useState(false)

  const searchbar: any = useRef();
  const handleKeyDown = (event: any) => {
    if (event.keyCode === 191) {
      event.preventDefault();
      searchbar?.current.focus();
    }
  }
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
  }, [])
  return (
    <MenuContext.Provider value={providerValue}>
      <AtomBox
        asChild
        minHeight={{
          xs: "auto",
          md: "100vh",
        }}
      >
        <Wrapper>
          <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
            {/* <TopBannerContainer height={topBannerHeight} isDark={isDark}>
              <SubContainer ref={increaseRef}>
                <Box display={['flex', 'flex', 'flex', 'block', 'block']}>
                  <Flex alignItems="center" width="max-content">
                    <img src="/images/chains/369.png" width="24" alt="hex" />
                    <Text fontSize={15} bold ml="4px" mr="2px">${pricesData?.prices?.PLS}</Text>
                    <Text color="secondary" fontSize={15} bold>PLS</Text>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center" marginLeft="4px">
                    <Text color={pricesData?.changes?.plsChange > 0 ? 'success' : 'failure'} fontSize={15} textAlign="center">
                      {pricesData?.changes?.plsChange}%
                    </Text>
                  </Flex>
                </Box>
                <Box display={['flex', 'flex', 'flex', 'block', 'block']}>
                  <Flex alignItems="center" width="max-content">
                    <img src="/icons/hex-icon.png" alt="hex" />
                    <Text fontSize={15} bold ml="4px" mr="2px">${pricesData?.prices?.HEX}</Text>
                    <Text color="secondary" fontSize={15} bold>HEX</Text>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center" marginLeft="4px">
                    <Text color={pricesData?.changes?.hexChange > 0 ? 'success' : 'failure'} fontSize={15} textAlign="center">
                      {pricesData?.changes?.hexChange}%
                    </Text>
                  </Flex>
                </Box>
                <Box display={['flex', 'flex', 'flex', 'block', 'block']}>
                  <Flex alignItems="center" width="max-content">
                    <img src="/icons/plsx-icon.png" alt="hex" />
                    <Text fontSize={15} bold ml="4px" mr="2px">${pricesData?.prices?.PLSX}</Text>
                    <Text color="secondary" fontSize={15} bold>PLSX</Text>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center" marginLeft="4px">
                    <Text color={pricesData?.changes?.plsxChange > 0 ? 'success' : 'failure'} fontSize={15} textAlign="center">
                      {pricesData?.changes?.plsxChange}%
                    </Text>
                  </Flex>
                </Box>
                <Box display={['flex', 'flex', 'flex', 'block', 'block']}>
                  <Flex alignItems="center" width="max-content">
                    <img src="/icons/inc-icon.png" alt="hex" />
                    <Text fontSize={15} bold ml="4px" mr="2px">${pricesData?.prices?.INC}</Text>
                    <Text color="secondary" fontSize={15} bold>INC</Text>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center" marginLeft="4px">
                    <Text color={pricesData?.changes?.incChange > 0 ? 'success' : 'failure'} fontSize={15} textAlign="center">
                      {pricesData?.changes?.incChange}%
                    </Text>
                  </Flex>
                </Box>
              </SubContainer>
              <Box display={['none', 'none', 'none', 'block', 'block']}>
                <Flex alignItems="center" width="110px" marginLeft="20px" justifyContent='space-between'>
                  <BuyPlusGradientButton onClick={() => window.open(`http://howtobuypulse.com/`, '_blank')}>
                    <AddCircleIcon width="18px" marginRight="4px" color="black" />
                    Buy PLS
                  </BuyPlusGradientButton>
                </Flex>
              </Box>
            </TopBannerContainer> */}
            <StyledNav isDark={isDark}>
              <Flex width={[null, "calc(100% - 90px)", null, "calc(100% - 340px)"]}>
                <Logo href={homeLink?.href ?? "/"} />
                <AtomBox display={{ xs: "none", md: "block" }}>
                  <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} isMainItem={true} />
                </AtomBox>
                <Flex width={[null, "100%", null, "calc(100% - 295px)"]} maxWidth="420px" alignItems="center" height="auto" position="relative" marginRight="12px">
                  <SearchInput placeholder="Search for collections, NFTs or users"
                    ref={searchbar}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && searchValue) {
                        window.open(`https://marketplace.hex.toys/search/${searchValue}`)
                        setSearchValue('')
                      }
                    }}
                  />
                  {!isMobile &&
                    <InputEnd>
                      /
                    </InputEnd>}
                </Flex>
              </Flex>
              <Flex alignItems="center" height="100%" minWidth={[null, null, null, "310px"]} justifyContent="space-between">
                {/* <AtomBox mr="12px" display={{ xs: "none", lg: "block" }}>
                  <CakePrice showSkeleton={false} cakePriceUsd={cakePriceUsd} />
                </AtomBox> */}
                <Box display={['none', 'none', 'none', 'block', 'block']}>
                  <ThemeSelector />
                </Box>
                {/* <MessageNotifier /> */}
                {(isMobile || isMd) &&
                  <HamburgerIcon marginLeft={10} height={28} width={28} cursor="pointer" className="hover"
                    onClick={() => setShowNavMenu(!showNavMenu)}
                  />}
                {!(isMobile || isMd) && rightSide}
              </Flex>
            </StyledNav>
          </FixedContainer>
          {(isMobile || isMd) &&
            <Box background="#3338" className={showNavMenu ? "navmenu-visible" : "navmenu-hidden"} left={0} width="100%" position="fixed" height="100vh" zIndex={25}
              onClick={() => { setShowNavMenu(false) }}
            >
              <Box
                onClick={(event) => { event.stopPropagation() }}
                backgroundColor="navmenu"
                height="fit-content"
                padding={20}
                paddingBottom={32}
                borderBottom="solid 1px"
                borderBottomColor="cardBorder"
              >
                <Flex justifyContent="flex-end">
                  <CloseIcon height={28} width={28} cursor="pointer" className={isDark ? 'dark-spread' : 'light-spread'}
                    onClick={() => setShowNavMenu(!showNavMenu)}
                  />
                </Flex>
                {links.map((val: any) => (
                  <SubItemContainer key={val.label}>
                    <Box as={linkComponent} href={val?.href} target={val?.label === 'NFT' ? '_blank' : ''} onClick={() => setShowNavMenu(false)}>
                      <Text fontSize={18} bold className={activeItem === val?.href ? "main-gradient-color" : ""}>
                        {val.label}
                      </Text>
                    </Box>
                  </SubItemContainer>
                ))}
                <SubItemContainer>
                  <ThemeSwitcher />
                </SubItemContainer>
                {/* <SubItemContainer>
                  <Flex alignItems="center" width="140px" justifyContent='space-between'>
                    <BuyPlusGradientButton onClick={() => window.open(`https://howtobuypulse.com/`, '_blank')}>
                      <AddCircleIcon width="18px" marginRight="4px" color="black" />
                      Buy PLS
                    </BuyPlusGradientButton>
                  </Flex>
                </SubItemContainer> */}
                <SubItemContainer>
                  {rightSide}
                </SubItemContainer>
              </Box>
            </Box>}
          {(subLinks && !isBridge) ? (
            <Flex justifyContent="space-around" overflow="hidden">
              <SubMenuItems
                items={subLinksWithoutMobile}
                activeItem={activeSubItem}
              />
            </Flex>
          ) : (
            <div />
          )}
          <BodyWrapper mt={!subLinks ? `${totalTopMenuHeight + 1}px` : "0"}>
            <Inner>{children}</Inner>
          </BodyWrapper>
        </Wrapper>
      </AtomBox>
      <Footer
        items={footerLinks}
        isDark={isDark}
        toggleTheme={toggleTheme}
        langs={langs}
        setLang={setLang}
        currentLang={currentLang}
        cakePriceUsd={cakePriceUsd}
        buyCakeLabel={buyCakeLabel}
        buyCakeLink={buyCakeLink}
        mb={[`${MOBILE_MENU_HEIGHT}px`, null, "0px"]}
      />
      {/* <AtomBox display={{ xs: "block", md: "none" }}>
        <BottomNav items={links} activeItem={activeItem} activeSubItem={activeSubItem} />
      </AtomBox> */}
    </MenuContext.Provider>
  );
};

export default Menu;
