import { useMemo } from 'react'
import { Abi, Address } from 'viem'
import { erc20ABI, useWalletClient } from 'wagmi'
import { getMulticallAddress } from 'utils/addressHelpers'
import {
  getContract,
  getMasterchefContract,
  getMasterchefV1Contract,
  getSouschefContract,
} from 'utils/contractHelpers'

// Imports below migrated from Exchange useContract.ts
import { ChainId, WNATIVE } from '@pancakeswap/sdk'
import { CAKE } from '@pancakeswap/tokens'
import erc721CollectionAbi from 'config/abi/erc721collection.json'
import IPancakePairABI from 'config/abi/IPancakePair.json'
import multiCallAbi from 'config/abi/Multicall.json'
import wethAbi from 'config/abi/weth.json'
import { useActiveChainId } from './useActiveChainId'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: string) => { return useContract(address, erc20ABI) }

export const useCake = () => {
  const { chainId } = useActiveChainId()
  return useContract(CAKE[chainId].address ?? CAKE[ChainId.PULSE].address, erc20ABI)
}

export const useMasterchef = () => {
  const { chainId } = useActiveChainId()
  const { data: signer } = useWalletClient()
  return useMemo(() => getMasterchefContract(signer, chainId), [signer, chainId])
}

export const useMasterchefV1 = () => {
  const { data: signer } = useWalletClient()
  return useMemo(() => getMasterchefV1Contract(signer), [signer])
}

export const useSousChef = (id) => {
  const { data: signer } = useWalletClient()
  return useMemo(() => getSouschefContract(id, signer), [id, signer])
}

export const useErc721CollectionContract = (collectionAddress: string) => {
  return useContract(collectionAddress, erc721CollectionAbi)
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
export function useContract<TAbi extends Abi | unknown[]>(
  addressOrAddressMap: string | { [chainId: number]: Address } | undefined,
  abi?: TAbi,
) {
  const { chainId } = useActiveChainId()
  const { data: walletClient } = useWalletClient()

  return useMemo(() => {
    if (!addressOrAddressMap || !abi || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract({
        abi,
        address,
        chainId,
        signer: walletClient,
      })
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, abi, chainId, walletClient])
}

export function useTokenContract(tokenAddress?: string) {
  return useContract(tokenAddress, erc20ABI)
}

export function useWNativeContract() {
  const { chainId } = useActiveChainId()
  return useContract(chainId ? WNATIVE[chainId]?.address : undefined, wethAbi)
}

export function usePairContract(pairAddress?: string) {
  return useContract(pairAddress, IPancakePairABI)
}

export function useMulticallContract() {
  const { chainId } = useActiveChainId()
  return useContract(getMulticallAddress(chainId), multiCallAbi)
}
