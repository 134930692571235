import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  DropdownMenuItems,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { SUPPORT_ONLY_BSC, SUPPORT_ONLY_PULSE } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Swap'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: false,
        items: [
          // {
          //   label: t('Trade'),
          //   href: '/trade',
          // },
          // {
          //    label: t('Swap'),
          //    href: '/swap',
          //  },
          // {
          //   label: t('Liquidity'),
          //   href: '/liquidity',
          // },      
          // {
          //   label: t('Bridge'),
          //   href: '/bridge',
          //   type: DropdownMenuItemType.EXTERNAL_LINK,
          // },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
      // {
      //   label: t('Statistics'),
      //   href: '/info',
      //   icon: TrophyIcon,
      //   fillIcon: TrophyFillIcon,
      //   supportChainIds: SUPPORT_ONLY_PULSE,
      //   hideSubNav: true,
      //   items: [
      //     // {
      //     //   label: t('PulseX Info'),
      //     //   href: '/info',
      //     //   hideSubNav: true
      //     // }
      //     {
      //       label: t('Overview'),
      //       href: '/info',
      //     },
      //     {
      //       label: t('Tokens'),
      //       href: '/info/tokens',
      //     },
      //     {
      //       label: t('Pairs'),
      //       href: '/info/pairs',
      //     },
      //   ].map((item) => addMenuItemSupported(item, chainId)),
      // },
      {
        label: t('NFT'),
        href: 'https://marketplace.hex.toys/',
        icon: NftIcon,
        fillIcon: NftFillIcon,
        supportChainIds: SUPPORT_ONLY_PULSE,
        image: '/images/decorations/nft.png',
        items: [
          {
            label: t('Overview'),
            href: 'https://marketplace.hex.toys/',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Collections'),
            href: 'https://marketplace.hex.toys/explore-collections',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Items'),
            href: 'https://marketplace.hex.toys/explore-items',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ],
      }
    ].map((item) => addMenuItemSupported(item, chainId))

export default config
