let tokenSelectSound: HTMLAudioElement
let swapSuccessSound: HTMLAudioElement
let swapFailureSound: HTMLAudioElement
let txSuccessSound: HTMLAudioElement
let txFailureSound: HTMLAudioElement

const tokenSelectSoundURL = 'https://tokens.hex.toys/sounds/token-select.wav'
const swapSuccessSoundURL = 'https://tokens.hex.toys/sounds/swap-success.mp3'
const swapFailureSoundURL = 'https://tokens.hex.toys/sounds/swap-failure.wav'
const txSuccessSoundURL = 'https://tokens.hex.toys/sounds/tx-success.mp3'
const txFailureSoundURL = 'https://tokens.hex.toys/sounds/tx-failure.wav'

export const getTokenSelectSound = () => {
  if (!tokenSelectSound) {
    tokenSelectSound = new Audio(tokenSelectSoundURL)
  }
  return tokenSelectSound
}

export async function playTokenSelectSound() {
  try {
    await getTokenSelectSound().play()
  } catch (err) { 
    // There might be some policy error when playing audio
  }
}

export const getSwapSuccessSound = () => {
  if (!swapSuccessSound) {
    swapSuccessSound = new Audio(swapSuccessSoundURL)
  }
  return swapSuccessSound
}

export async function playSwapSuccessSound() {
  try {
    await getSwapSuccessSound().play()
  } catch (err) { 
    // There might be some policy error when playing audio
  }
}

export const getSwapFailureSound = () => {
  if (!swapFailureSound) {
    swapFailureSound = new Audio(swapFailureSoundURL)
  }
  return swapFailureSound
}

export async function playSwapFailureSound() {
  try {
    await getSwapFailureSound().play()
  } catch (err) { 
    // There might be some policy error when playing audio
  }
}

export const getTxSuccessSound = () => {
  if (!txSuccessSound) {
    txSuccessSound = new Audio(txSuccessSoundURL)
  }
  return txSuccessSound
}

export async function playTxSuccessSound() {
  try {
    await getTxSuccessSound().play()
  } catch (err) { 
    // There might be some policy error when playing audio
  }
}

export const getTxFailureSound = () => {
  if (!txFailureSound) {
    txFailureSound = new Audio(txFailureSoundURL)
  }
  return txFailureSound
}

export async function playTxFailureSound() {
  try {
    await getTxFailureSound().play()
  } catch (err) { 
    // There might be some policy error when playing audio
  }
}