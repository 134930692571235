import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      {/* <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 11.7792 21.9928 11.5602 21.9788 11.343C18.6515 16.824 10.797 19.3967 6.32085 20.232C7.93393 21.3469 9.8907 22 12 22ZM3.6987 17.5775C2.62604 15.9842 2 14.0652 2 12C2 6.47715 6.47715 2 12 2C16.6548 2 20.5667 5.18031 21.6815 9.48656C20.7816 11.0755 19.4244 12.3811 17.8282 13.4444V7.27607C17.8282 6.86948 17.4986 6.53988 17.092 6.53988H15.3742C14.9676 6.53988 14.638 6.86948 14.638 7.27607V15.0795C14.638 15.1034 14.6392 15.1269 14.6413 15.1501C14.2758 15.3076 13.906 15.4562 13.5337 15.5963V9.36196C13.5337 8.95537 13.2041 8.62577 12.7975 8.62577H11.0798C10.6732 8.62577 10.3436 8.95537 10.3436 9.36196V16.592C9.97218 16.6864 9.60348 16.7732 9.23926 16.8528V11.4479C9.23926 11.0413 8.90966 10.7117 8.50307 10.7117H6.78528C6.37869 10.7117 6.04908 11.0413 6.04908 11.4479V17.3941C5.17906 17.4987 4.38348 17.5575 3.6987 17.5775Z"
      /> */}
      <path fillRule="evenodd" clipRule="evenodd" d="M12 24.0007C18.6274 24.0007 24 18.6281 24 12.0007C24 11.7358 23.9914 11.473 23.9746 11.2123C19.9818 17.7895 10.5564 20.8768 5.18502 21.8791C7.12072 23.217 9.46884 24.0007 12 24.0007ZM2.03844 18.6937C0.751248 16.7818 0 14.479 0 12.0007C0 5.37331 5.37258 0.000732422 12 0.000732422C17.5858 0.000732422 22.28 3.8171 23.6178 8.9846C22.5379 10.8913 20.9093 12.4581 18.9938 13.734V6.33202C18.9938 5.84411 18.5983 5.44859 18.1104 5.44859H16.049C15.5611 5.44859 15.1656 5.84411 15.1656 6.33202V15.6961C15.1656 15.7248 15.167 15.753 15.1696 15.7809C14.731 15.9699 14.2872 16.1482 13.8404 16.3163V8.83508C13.8404 8.34718 13.4449 7.95166 12.957 7.95166H10.8958C10.4078 7.95166 10.0123 8.34718 10.0123 8.83508V17.5111C9.56662 17.6244 9.12418 17.7286 8.68711 17.8241V11.3382C8.68711 10.8503 8.29159 10.4548 7.80368 10.4548H5.74234C5.25443 10.4548 4.8589 10.8503 4.8589 11.3382V18.4737C3.81487 18.5992 2.86018 18.6697 2.03844 18.6937Z" fill="url(#paint0_linear_9_1295)" />
      <defs>
        <linearGradient id="paint0_linear_9_1295" x1="-6.64021e-09" y1="12.0007" x2="19.0142" y2="12.0351" gradientUnits="userSpaceOnUse">
          <stop stopColor="#69EACB" />
          <stop offset="1" stopColor="#6654F1" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
