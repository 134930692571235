import { vars } from "@pancakeswap/ui/css/vars.css";
import { useIsMounted } from "@pancakeswap/hooks";
import styled from "styled-components";
import React from "react";
import { Box, Flex } from "../Box";
import { Link } from "../Link";
import {
  StyledFooter,
  StyledIconMobileContainer,
  StyledList,
  StyledListItem,
  StyledSocialLinks,
  StyledText,
  StyledToolsContainer,
} from "./styles";

import { Button } from "../Button";
import CakePrice from "../CakePrice/CakePrice";
import LangSelector from "../LangSelector/LangSelector";
import { ThemeSwitcher } from "../ThemeSwitcher";
import { FooterProps } from "./types";
import { SkeletonV2 } from "../Skeleton";

const SecondaryText = styled.div`
  color: #7780A1;
  margin-top: 16px;
  font-weight: 300;
  margin-bottom: 16px;
`
const ImgBtn = styled.img`
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
  margin-top: 16px;
`
const CustomFlex = styled(Flex)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 40px;
  @media screen and (max-width: 852px) { 
    padding-bottom: 20px;
    margin-bottom: 16px;
  } 
  @media screen and (max-width: 550px) { 
    display: block;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`
const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  buyCakeLink,
  ...props
}) => {
  const isMounted = useIsMounted();
  return (
   <></>
  );
};

export default MenuItem;
